import { Component, inject, signal } from '@angular/core';
import { ServiceName } from '../../enums/service-name.enum';
import { DevToolsService } from '../../services/dev-tools.service';

@Component({
  selector: 'hola-dev-tools',
  standalone: true,
  imports: [],
  templateUrl: './dev-tools.component.html',
  styleUrl: './dev-tools.component.scss',
})
export class DevToolsComponent {
  // TODO mostrar opciones de desarrollo
  // TODO forzar llamada a servicios (selector de servicios)
  // TODO mostrar etiquetas

  private readonly devToolsService = inject(DevToolsService);

  public isVisible = signal(false);

  public toggleVisibility(): void {
    this.isVisible.set(!this.isVisible());
  }
  public toggleTranslate(): void {
    this.devToolsService.toggleTranslate();
  }
  public toggleApiError(): void {
    this.devToolsService.toggleApiError();
  }
  public togglePurchaseApiError(): void {
    this.devToolsService.toggleSpecificApiError(ServiceName.Order);
  }
}
