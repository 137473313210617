<button (click)="toggleVisibility()">
  Mostrar/Ocultar Herramientas de Desarrollo
</button>
@if (isVisible()) {
  <div>
    <label>
      <input type="checkbox" (change)="toggleTranslate()" />
      <span>Traducciones</span>
    </label>
  </div>
  <div>
    <label>
      <input type="checkbox" (change)="toggleApiError()" />
      <span>Error API</span>
    </label>
  </div>
  <div>
    <label>
      <input type="checkbox" (change)="togglePurchaseApiError()" />
      <span>Error API Compra</span>
    </label>
  </div>
}
