import {
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';

import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateModule } from '@ngx-translate/core';
import { routes } from './app.routes';
import { forceApiErrorInterceptor } from './shared/interceptor/force-api-error.interceptor';

// Reset posición scroll al navegar
const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);
//

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      inMemoryScrollingFeature,
      withComponentInputBinding(),
    ),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptors([forceApiErrorInterceptor]),
    ),
    provideAnimationsAsync(),
    importProvidersFrom(TranslateModule.forRoot()),
    [
      { provide: LOCALE_ID, useValue: 'en-EN' },
      // { provide: LOCALE_ID, useValue: 'ca-CA' },
      // { provide: LOCALE_ID, useValue: 'es-ES' },
      // { provide: LOCALE_ID, useValue: 'de-DE' },
      // { provide: LOCALE_ID, useValue: 'fr-FR' },
      // { provide: LOCALE_ID, useValue: 'it-it' },
    ],
  ],
};
