import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hola-modal',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  public title!: string;
  public body!: string;

  public bsModalRef = inject(BsModalRef);

  public close(): void {
    this.bsModalRef.hide();
  }
}
