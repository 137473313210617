import { JsonPipe, registerLocaleData } from '@angular/common';
import localeCa from '@angular/common/locales/ca';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { RouterLink, RouterOutlet } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PageComponent } from './page/page.component';
import { DevToolsComponent } from './shared/components/dev-tools/dev-tools.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { metaTags } from './shared/gtm/meta-tag.config';
import { ErrorService } from './shared/services/error.service';
import { Url, UrlService } from './shared/services/url.service';

@Component({
  selector: 'hola-root',
  standalone: true,
  imports: [
    RouterOutlet,
    PageComponent,
    ReactiveFormsModule,
    JsonPipe,
    RouterLink,
    DevToolsComponent,
    ModalComponent,
  ],
  providers: [BsModalService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private meta = inject(Meta);
  private urlService = inject(UrlService);
  private errorService = inject(ErrorService);
  private bsModalService = inject(BsModalService);

  private error$ = toObservable(this.errorService.error);
  private url$ = toObservable(this.urlService.url);

  constructor() {
    registerLocaleData(localeEn, 'en-EN');
    registerLocaleData(localeCa, 'ca-CA');
    registerLocaleData(localeEs, 'es-ES');
    registerLocaleData(localeDe, 'de-DE');
    registerLocaleData(localeFr, 'fr-FR');
    registerLocaleData(localeIt, 'it-IT');

    // Cuando se produce un error en un servicio específico mostramos el modal de error
    this.error$
      .pipe(takeUntilDestroyed())
      .subscribe((error) => this.showError(error));

    // GTM - Con cada cambio de página actualizamos el tag del meta para las analíticas.
    this.url$
      .pipe(takeUntilDestroyed())
      .subscribe((url) => this.updateMetaTag(url));
  }

  private updateMetaTag(url: Url | null): void {
    const pageId = url?.getPageId();
    if (pageId) {
      this.meta.updateTag(
        {
          id: 'enlace_en',
          'data-enlace': metaTags[pageId],
        },
        'id=enlace_en',
      );
    }
  }

  private showError(error: string | null): void {
    if (!error) {
      return;
    }
    this.bsModalService.show(ModalComponent, {
      initialState: { title: '', body: error },
    });
    this.errorService.clear();
  }
}
