import { PageId } from '../enums/page-id.enum';

export const metaTags = {
  [PageId.About]: '/about-us',
  [PageId.AppHolaBarcelona]: '/hola-barcelona-app',
  [PageId.Cart]: '/cart',
  [PageId.Checkout]: '/cart/payment',
  [PageId.Contact]: '/contact-us',
  [PageId.ContactMobile]: '/contact-us-mobile',
  [PageId.Cookies]: '/cookies-policy',
  [PageId.Discover]: '/discover-hola-barcelona',
  [PageId.DiscoverCatalunya]: '/tickets/tours-from-barcelona',
  [PageId.Error]: '/error',
  [PageId.Faq]: '/faq',
  [PageId.FaqMobile]: '/faq-mobile',
  [PageId.Home]: '/',
  [PageId.HowToDiscover]: '/how-to-discover',
  [PageId.Legal]: '/legal-notice',
  [PageId.LegalAdviceSignup]: '/terms-and-conditions-hola-barcelona',
  [PageId.MouteAmbHola]: '/get-around-with-hola-barcelona',
  [PageId.Recover]: '/voucher/recover',
  [PageId.Sitemap]: '/site-map',
  [PageId.TermsAndConditions]: '/conditions',
  [PageId.Thanks]: '/thanks',
  [PageId.Ticket]: '/tickets',
  [PageId.Tickets]: '/buy-tickets',
  [PageId.TransportDealsLanding]: '/transport-deals',
  [PageId.Unsubscribe]: '/unsubscribe-email',
};
