<section>
  <div class="p-4 text-dark">
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="close()"
    ></button>
    <p class="body-error">
      <i class="fa fa-exclamation-circle info-error" aria-hidden="true"></i
      ><br />
      {{ body }}
    </p>
  </div>
</section>
