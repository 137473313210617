import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ServiceName } from '../enums/service-name.enum';
import { DevToolsService } from '../services/dev-tools.service';
import { OrderApiService } from '../services/order-api.service';

export const forceApiErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const devToolsService = inject(DevToolsService);
  const orderApiService = inject(OrderApiService);

  const forceErrorUrl = `${req.url}Error`;

  if (devToolsService.isApiErrorToggleChecked()) {
    return next(req.clone({ url: forceErrorUrl }));
  }

  if (
    devToolsService.specificApiError() === ServiceName.Order &&
    req.url === orderApiService.orderApiUrl
  ) {
    return next(req.clone({ url: forceErrorUrl }));
  }

  return next(req);
};
