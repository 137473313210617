import { computed, inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguage } from '../enums/app-language.enum';
import { ServiceName } from '../enums/service-name.enum';
import { TranslateLoaderService } from './translate-loader.service';

interface DevToolsState {
  toggleTranslate: boolean;
  toggleGeneralApiError: boolean;
  toggleSpecificApiError: string | null;
}
@Injectable({
  providedIn: 'root',
})
export class DevToolsService {
  private readonly translateService = inject(TranslateService);
  private readonly translateLoaderService = inject(TranslateLoaderService);

  private state = signal<DevToolsState>({
    toggleTranslate: false,
    toggleGeneralApiError: false,
    toggleSpecificApiError: null,
  });

  public isApiErrorToggleChecked = computed(
    () => this.state().toggleGeneralApiError,
  );
  public isTranslateToggleChecked = computed(
    () => this.state().toggleTranslate,
  );
  public specificApiError = computed(() => this.state().toggleSpecificApiError);

  public toggleTranslate(): void {
    this.state.update((state) => ({
      ...state,
      toggleTranslate: !state.toggleTranslate,
    }));

    if (this.state().toggleTranslate) {
      this.translateService.setDefaultLang('');
      this.translateService.setTranslation('', {});
      this.translateService.use('');
    } else {
      const currentTranslationLang =
        (this.translateLoaderService.currentTranslation()
          ?.lang as AppLanguage) || AppLanguage.EN;
      this.translateLoaderService.load(currentTranslationLang, true);
    }
  }

  public toggleApiError(): void {
    this.state.update((state) => ({
      ...state,
      toggleGeneralApiError: !state.toggleGeneralApiError,
    }));
  }

  public toggleSpecificApiError(name: ServiceName): void {
    this.state.update((state) => ({
      ...state,
      toggleSpecificApiError: name,
    }));
  }
}
