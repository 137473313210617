import { Routes } from '@angular/router';
import { pageResolver } from './shared/routes/page.resolver';

export const routes: Routes = [
  {
    path: '',
    resolve: { params: pageResolver },
    loadComponent: () =>
      import('../app/page/page.component').then((c) => c.PageComponent),
  },
  {
    path: ':lang',
    loadChildren: () =>
      import('./shared/routes/page.routes').then((r) => r.routes),
  },
];
